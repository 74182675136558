<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateDependency">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input
        v-model="dependency.name"
        class="form-control"
        id="name"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/dof_dependencies";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      dependency: {
        name: "",
      },
      dependencyId: this.$route.params.id,
    };
  },
  mounted() {
    this.getDependency();
  },
  methods: {
    getDependency() {
      if (!this.dependencyId) {
        return;
      }
      find(this.dependencyId).then((response) => {
        this.dependency = response.data;
      });
    },
    saveOrUpdateDependency() {
      if (this.dependencyId) {
        this.updateDependency();
      } else {
        this.saveDependency();
      }
    },
    saveDependency() {
      create(this.dependency).then(() => {
        showNotification(201);
        this.$router.push("/admin/dependencias");
      });
    },
    updateDependency() {
      update(this.dependencyId, this.dependency).then(() => {
        showNotification(201);
        this.$router.push("/admin/dependencias");
      });
    },
  },
};
</script>
